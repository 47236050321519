import html from '../utils/html'
import truth from '../utils/truth'
import api from '../utils/api'
import React, {useState, useEffect} from 'react'
import {Checkbox, Button, Table, Popup, Modal, Image} from 'semantic-ui-react'
import Tables from '../utils/tables'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

export default function CheckRow(props) {
  const [editing, setEditing] = useState(false);
  const [checkNumber, setCheckNumber] = useState(props.check.check_number);
  const [checkDate, setCheckDate] = useState(new Date(Date.parse(props.check.check_date)));
  const [scanDate, setScanDate] = useState(new Date(Date.parse(props.check.created_at)));
  const [vendorName, setVendorName] = useState(props.check.vendor_name);
  const [amount, setAmount] = useState(props.check.amount);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(null);

  // common button
  const button = (key, color, icon, text, callback) => {
    return html.tag(Popup, key, {
      trigger: html.button(key, {
        onClick: (e) => callback(e),
        className: `ui ${color} button`
      }, html.i('icon', {className: `${icon} icon`})),
    }, text);
  }

  //
  // button callbacks
  //

  const edit = (e) => {
    setEditing(true);
  }

  const save = (e) => {
    setEditing(false) ;
    api.put(`/api/v1/checks/${props.check.id}`, {
      check: {
        check_number: checkNumber,
        check_date: checkDate,
        vendor_name: vendorName,
        amount: amount
      }
    }, {}).then((response) => response.json())
      .catch((error) => console.log('error', error))
  }


  const viewCheck = (e) => {
    setOpen(true)
    api.get(`/api/v1/checks/${props.check.id}`, {}, {})
      .then(response => response.json())
      .then((data) => {
        setCheck(data);
      })
  }

  const checkView = (e) => {
    if(truth.isTruthy(check) && truth.isTruthy(check.image)) {
      if(truth.isTruthy(check.images) && check.images.length > 0) {
        return html.tag('div', 'check-images', {}, check.images.map((image) => {
          return html.tag(Image, 'check-image', {src: `data:image/png;base64,${image}`});
        }));
      }
      return html.tag(Image, 'check-image', {src: `data:image/png;base64,${check.image}`});
    }
    return 'Loading...';
  }

  //
  // buttons
  //

  const approveButton = () => {
    return button('approve', 'green', 'thumbs up', 'Approve', (e) => {
      api.post(`/api/v1/checks/${props.check.id}/approve`, {}, {})
        .then(response => props.onDelete(e))
    });
  }

  const print_select = () => {
    const [selected, setSelected] = useState(props.checksToPrint.has(props.check.id));

    const result = html.tag(Checkbox, 'action', {
      onChange: () => {
        let checks = props.checksToPrint;
        if (!selected)
        checks.add(props.check.id);
        else
        checks.delete(props.check.id);

        props.setChecksToPrint(checks);
        setSelected(!selected);
      },
      checked: selected
    });

    return html.div('select-for-printing', {
      className: 'center aligned'
    }, result);
  }

  const cancelEditButton = () => {
    return button('cancel', 'red', 'close', 'Cancel Edit', (e) => {
      setEditing(false);
    });
  }

  const editButton = () => {
    return button('edit', 'secondary', 'pencil', 'Edit', edit);
  }

  const saveButton = () => {
    return button('save', 'green', 'save', 'Save', save);
  }

  const viewButton = () => {
    return html.span('button-modal', {},
      button('view', 'primary', 'search plus', 'View', (e) => viewCheck(e)),
      html.tag(Modal, 'view-modal', {
        open: open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false)
      },[
        html.tag(Modal.Content, 'modal-content', {}, checkView())
      ])
    );
  }

  const deleteButton = () => {
    return button('delete', 'red', 'trash', 'Delete', (e) => {
      api.delete(`/api/v1/checks/${props.check.id}`)
        .then(response => {
          if(!response.ok)
            throw new Error(response);
          props.onDelete(response);
        })
        .catch(error => console.log('delete - error', error))
    });
  }

  const actionsColumn = () => {
    if(editing)
      return html.span('buttons', {className: 'ui grid'}, [
        html.span('left', {className: 'center aligned sixteen wide column'},
          saveButton(),
          cancelEditButton()
        )
      ]);
    return html.span('buttons', {className: 'ui grid'}, [
      html.span('left', {className: 'center aligned sixteen wide column'},
        editButton(),
        viewButton(),
        approveButton(),
        deleteButton()
      )
    ]);
  }

  const checkNumberColumn = () => {
    if(editing)
      return html.div('check_number', {
        className: 'ui fluid input'
      },
        html.input('check_number', {
          onChange: (e) => setCheckNumber(e.target.value),
          type: 'number',
          value: checkNumber
        })
      );
    return checkNumber;
  }

  const checkDateColumn = () => {
    if(editing) {
      let options = {
        onChange: (e, data) => setCheckDate(data.value)
      }
      if(truth.isTruthy(checkDate) && checkDate != 'Invalid Date')
        options['value'] = checkDate;
      return html.tag(SemanticDatepicker, 'date-picker', options);
    }
    return checkDate.toLocaleDateString();
  }

  const scanDateColumn = () => {
    return scanDate.toLocaleDateString();
  }

  const vendorNameColumn = () => {
    if(editing)
      return html.div('vendor_name', {
        className: 'ui fluid input'
      },
        html.input('vendor_name', {
          onChange: (e) => setVendorName(e.target.value),
          value: vendorName
        })
      );
    return vendorName;
  }

  const amountColumn = () => {
    if(editing)
      return html.div('amount', {
        className: 'ui right labeled fluid input'
      },
        html.label('label', {className: 'ui label'}, '$'),
        html.input('amount', {
          onChange: (e) => setAmount(e.target.value),
          type: 'number',
          value: amount
        })
      );
    return amount;
  }

  const columns = () => {
    return [
        {react: print_select(props.setchecksToPrint)},
        {react: actionsColumn()},
        {react: checkNumberColumn(), className: 'right aligned'},
        {react: checkDateColumn(), className: 'right aligned'},
        {react: scanDateColumn(), className: 'right aligned'},
        {react: vendorNameColumn()},
        {react: amountColumn(), className: 'right aligned'}
      ];
  };

  return html.tag(Tables.Row, 'row', {columns: columns()});
}