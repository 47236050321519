import html from '../utils/html'
import truth from '../utils/truth'
import api from '../utils/api'
import React, {useState, useEffect} from 'react'
import {Button, Checkbox,Table} from 'semantic-ui-react'
import Tables from '../utils/tables'
import useFetchWithErrors from './../utils/use_fetch_with_errors'
import usePagination from './../utils/use_pagination'
import CheckRow from './check_row'


export default function ChecksIndex(props) {
  const {div, tag, i} = html;
  const [pagination, setPagination] = usePagination();
  const [checksToPrint, setChecksToPrint] = useState(new Set());
  const [checks, setChecks, fetch] = useFetchWithErrors(`/api/v1/checks/scanned`, (data) => {
    // update pagination since we don't know the number of pages beforehand
    setPagination({
      pages: data.pages,
      current_page: data.current_page,
      per_page: pagination.per_page
    });
    return data;
  });

  const printButton = () => {
    let opts = {
      icon: 'print',
      onClick: () => {
        if (checksToPrint.size == 0) {
          // todo: semantic ui message, not popup alert
          alert('Please select at least one check to print');
          return;
        }
        let url = '/api/v1/checks/pdf.pdf';
        let params = api.EncodeQueryString({check_ids: [...checksToPrint]});
        window.open(`${url}${params}`, '_blank');
      }
    };

    return html.tag(Button, 'print-button', opts);
  }

  // combine filters with pagination for our fetch
  const filters_with_pagination = () => {
    let values = {};
    values['current_page'] = pagination.current_page;
    values['pages'] = pagination.pages;
    values['per_page'] = pagination.per_page;
    return values;
  }

  const reload = () => {
    fetch(filters_with_pagination());
  }

  // register the fetch effect to fire when pagination updates
  useEffect(() => {
    reload();
  }, [pagination]);

  const buttonColumn = (check, index) => {
    return html.span('key', {}, 'test');
  }

  const tableRow = (check, index) => {
    return {
      react: html.tag(CheckRow, index, {
        check: check,
        onDelete: (e) => reload(),
        setChecksToPrint: setChecksToPrint,
        checksToPrint: checksToPrint,
      })
    };
  };

  const rows = () => {
    if(truth.isTruthy(checks) && truth.isTruthy(checks.entities))
      return checks.entities.map((check, index) => tableRow(check, index));
    return null;
  }

  return div('component', {className: ''},
    html.h1('header', {className: 'ui header'}, 'Check Scans'),
    html.tag(Tables.Table, 'checks-table', {
      columns: [
        {text: '', react: printButton()},
        {text: 'Actions'},
        {text: 'Check #'},
        {text: 'Check Date'},
        {text: 'Scan Date'},
        {text: 'Vendor'},
        {text: 'Amount'}
      ],
      rows: rows()
    })
  );
}