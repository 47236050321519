import html from '../utils/html'
import truth from '../utils/truth'
import React, {useState, useEffect} from 'react'
import {Table, Pagination, Modal, Grid} from 'semantic-ui-react'
import Tables from '../utils/tables'
import useFetchWithErrors from './../utils/use_fetch_with_errors'
import usePagination from './../utils/use_pagination'
import UserRow from './user_row'
import UserForm from './form'


export default function UsersIndex(props) {
  const {div, tag, i, h1} = html;
  const [pagination, setPagination] = usePagination();
  const [newUser, setNewUser] = useState(false);
  const [users, setUsers, fetch] = useFetchWithErrors(`/api/v1/users`, (data) => {
    // update pagination since we don't know the number of pages beforehand
    setPagination({
      pages: data.pages,
      current_page: data.current_page,
      per_page: pagination.per_page
    });
    return data.users;
  });

  // combine filters with pagination for our fetch
  const filters_with_pagination = () => {
    let values = {};
    values['current_page'] = pagination.current_page;
    values['pages'] = pagination.pages;
    values['per_page'] = pagination.per_page;
    return values;
  }

  const reload = () => {
    fetch(filters_with_pagination());
  }

  // register the fetch effect to fire when pagination updates
  useEffect(() => {
    reload();
  }, [pagination]);

  const buttonColumn = (user, index) => {
    return html.span('key', {}, 'test');
  }

  const tableRow = (user, index) => {
    return {
      react: html.tag(UserRow, index, {
        user: user,
        onDelete: (e) => reload(),
        onSave: (e) => reload()
      })
    };
  };

  const rows = () => {
    if(truth.isTruthy(users) && truth.isTruthy(users.entities))
      return users.entities.map((user, index) => tableRow(user, index));
    return null;
  }

  const newUserButton = () => {
    return html.button('new-button', {
      className: 'ui primary button',
      onClick: (e) => setNewUser(true)
    },
    'New User');
  }

  const header = () => {
    return div('header', {}, [
      h1('heading', {className: 'ui header'}, 'Users')
    ]);
  }

  const tableOptions = (index) => {
    return html.tag(Grid, `table-options-${index}`, {}, [
      html.tag(Grid.Column, 'left', {floated: 'left', width: 8},
        html.tag(Pagination, 'pagination', {
          activePage: pagination.current_page,
          totalPages: pagination.pages,
          onPageChange: (e, {activePage}) => {
            setPagination({
              current_page: activePage,
              per_page: pagination.per_page,
              pages: pagination.pages
            });
          }
        }),
      ),
      html.tag(Grid.Column, 'right', {floated: 'right', width: 2},
        newUserButton()
      )
    ]);
  }

  const newUserForm = () => {
    return html.tag(UserForm, 'user-form', {
      onClose: () => setNewUser(false),
      onSave: () => {
        setNewUser(false);
        reload();
      }
    });
  }

  const modal = () => {
    return html.tag(Modal, 'modal', {
        open: newUser,
        onOpen: () => setNewUser(true),
        onClose: () => setNewUser(false)
      }, html.tag(Modal.Content, 'modal-content', {}, newUserForm())
    );
  }

  return div('component', {className: ''},
    header(),
    tableOptions('top'),
    html.tag(Tables.Table, 'users-table', {
      columns: [
        {text: 'Actions'},
        {text: 'Name'},
        {text: 'Email'},
        {text: 'Roles'}
      ],
      rows: rows()
    }),
    tableOptions('bottom'),
    modal()
  );
}