import html from '../utils/html'

export default function TestComponent(props) {
  return html.div('some-key', {className: 'ui grid'}, [
      html.div('first', {className: 'four wide column'}, 'This'),
      html.div('second', {className: 'four wide column'}, 'is'),
      html.div('third', {className: 'four wide column'}, 'a'),
      html.div('fourth', {className: 'four wide column'}, 'test.')
    ]
  );
}