import html from '../utils/html'
import truth from '../utils/truth'
import MenuItem from './menu_item'

export default function MainMenu(props) {
  return html.div('menu', {
      className: 'ui vertical inverted menu labeled main-menu',
      style: {height: '100vh'}
    },
    props.menus.map((menu, index) => {
      menu['index'] = index;
      return MenuItem(menu);
    })
  );
};