import html from '../utils/html'
import truth from '../utils/truth'
import api from '../utils/api'
import React, {useState, useEffect} from 'react'
import {Button, Checkbox, Table, Pagination, Modal, Image} from 'semantic-ui-react'
import Tables from '../utils/tables'
import useFetchWithErrors from './../utils/use_fetch_with_errors'
import usePagination from './../utils/use_pagination'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

function CheckRow(props) {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(null);

  const checkView = (e) => {
    if(truth.isTruthy(check)) {
      if(truth.isTruthy(check.image)) {
        if(truth.isTruthy(check.images) && check.images.length > 0) {
          return html.tag('div', 'check-images', {}, check.images.map((image) => {
            return html.tag(Image, 'check-image', {src: `data:image/png;base64,${image}`});
          }));
        }
        return html.tag(Image, 'check-image', {src: `data:image/png;base64,${check.image}`});
      }
      if(truth.isTruthy(check.errors))
        return check.errors;
    }
    return 'Loading...';
  }

  const viewCheck = (id) => {
    setOpen(true)
    api.get(`/api/v1/checks/${id}`, {}, {})
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        setCheck(data);
      })
      .catch(error => {
        error.then((error) => {
          setCheck({errors: error.json.errors})
        })
      })
  }

  const checkNumberCell = (check) => {
    return html.a('link', {
      onClick: () => {viewCheck(check.id)}
    },
    check.check_number,
      html.tag(Modal, 'view-modal', {
        open: open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false)
      },[
        html.tag(Modal.Content, 'modal-content', {}, checkView())
      ])
    );
  }

  const print_select = () => {
    const [selected, setSelected] = useState(props.checksToPrint.has(props.check.id));

    const result = html.tag(Checkbox, 'action', {
      onChange: () => {
        let checks = props.checksToPrint;
        if (!selected)
        checks.add(props.check.id);
        else
        checks.delete(props.check.id);

        props.setChecksToPrint(checks);
        setSelected(!selected);
      },
      checked: selected
    });

    return html.div('select-for-printing', {
      className: 'center aligned'
    }, result);
  }

  return html.tag(Tables.Row, 'row', {columns: [
    {react: print_select(props.setchecksToPrint)},
    {react: checkNumberCell(props.check), className: 'right aligned'},
    {react: props.check.vendor_name},
    {react: props.check.amount, className: 'right aligned'},
    {react: props.check.check_date, className: 'right aligned'},
    {react: props.check.created_at.substring(0,10), className: 'right aligned'} // created_at is a DateTime, so we use a substring to remove the time
  ]});
}

export default function ScansCount(props) {
  const [checksToPrint, setChecksToPrint] = useState(new Set());
  const {div, tag, i} = html;
  const [pagination, setPagination] = usePagination();
  const [checkNumber, setCheckNumber] = useState('');
  const [checkDate, setCheckDate] = useState(new Date(Date.parse(null)));
  const [scanDate, setScanDate] = useState(new Date(Date.parse(null)));
  const [vendorName, setVendorName] = useState('');
  const [amount, setAmount] = useState('');
  const [checkCount, setCheckCount] = useState(0);
  const [checks, setChecks, fetch] = useFetchWithErrors(`/api/v1/reports/scans_count`, (data) => {
    // update pagination since we don't know the number of pages beforehand
    setPagination({
      pages: data.pages,
      current_page: data.current_page,
      per_page: pagination.per_page
    });
    setCheckCount(data.check_count);
    return data.checks;
  });

  const tableRow = (check, index) => {
    return {
      react: html.tag(CheckRow, index, {
        check: check,
        setChecksToPrint: setChecksToPrint,
        checksToPrint: checksToPrint,
      })
    };
  };

  const filterHasValue = (value) => {
    return truth.isTruthy(value);
  }

  const noFilterHasValue = () => {
    return !filterHasValue(checkNumber)
      // && !filterHasValue(checkDate)
      && !filterHasValue(vendorName)
      && !filterHasValue(amount);
  }

  // combine filters with pagination for our fetch
  const filters_with_pagination = () => {
    let values = {};
    values['current_page'] = pagination.current_page;
    values['pages'] = pagination.pages;
    values['per_page'] = pagination.per_page;
    if(filterHasValue(checkNumber))
      values['check_number'] = checkNumber;
    if(filterHasValue(vendorName))
      values['vendor_name'] = vendorName;
    if(filterHasValue(amount))
      values['amount'] = amount;
    if(filterHasValue(checkDate) && checkDate != 'Invalid Date') {
      let value = checkDate;
      // convert to array if needed
      if(!Array.isArray(value))
        value = [value];

      // map values to date
      value = value.map((v) => v.toLocaleDateString());
      values['check_date'] = value;
    }

    if(filterHasValue(scanDate) && scanDate != 'Invalid Date') {
      let value = scanDate;
      // convert to array if needed
      if(!Array.isArray(value))
        value = [value];

      // map values to date
      value = value.map((v) => v.toLocaleDateString());
      values['created_at'] = value;
    }

    return values;
  }

  const reload = () => {
    fetch(filters_with_pagination());
  }

  // register the fetch effect to fire when pagination updates
  useEffect(() => {
    reload();
  }, [pagination, checkNumber, vendorName, amount, checkDate, scanDate]);

  const rows = () => {
    if(truth.isTruthy(checks) && truth.isTruthy(checks.entities))
      return checks.entities.map((check, index) => tableRow(check, index));
    return null;
  }

  const filterWrapper = (key, field, label) => {
    return html.div(key, {className: 'field'},
      html.label('label', {className: 'ui header'}, label),
      field()
    );
  }

  const filterDateRangeCheckDate = (value, update) => {
    let options = {
      format: 'YYYY-MM-DD',
      onChange: (e, data) => {
        update(data.value);
      },
      type: 'range',
      className: 'ui fluid'
    }
    if(truth.isTruthy(checkDate) && checkDate != 'Invalid Date')
      options['value'] = checkDate;
    return html.div('wrapper', {className: 'ui'}, html.tag(SemanticDatepicker, 'date-picker', options));
  }

  const filterDateRangeScanDate = (value, update) => {
    let options = {
      format: 'YYYY-MM-DD',
      onChange: (e, data) => {
        update(data.value);
      },
      type: 'range',
      className: 'ui fluid'
    }
    if(truth.isTruthy(scanDate) && scanDate != 'Invalid Date')
      options['value'] = scanDate;
    return html.div('wrapper', {className: 'ui'}, html.tag(SemanticDatepicker, 'date-picker', options));
  }

  const filterInput = (value, update, type) => {
    return html.div('input', {
      className: 'ui fluid input'
    },
      html.input('input', {
        onChange: (e) => update(e.target.value),
        type: type,
        value: value(),
        placeholder: 'Search...'
      })
    );
  }

  const paginationSection = () => {
    return html.tag(Pagination, 'pagination', {
      activePage: pagination.current_page,
      totalPages: pagination.pages,
      onPageChange: (e, {activePage}) => {
        setPagination({
          current_page: activePage,
          per_page: pagination.per_page,
          pages: pagination.pages
        });
      }
    });
  }

  const totalsAndPagination = (key) => {
    return html.div(key, {className: 'segment'},
      html.div('totals', {className: 'ui vertical menu floated left'},
        html.span('label', {className: 'item'}, [
          'Total:',
          html.div('number', {className: 'ui label'}, checkCount),
        ])
      ),
      html.div('pagination', {className: 'ui menu floated right'},
        paginationSection()
      )
    );
  }

  const printButton = () => {
    let opts = {
      icon: 'print',
      onClick: () => {
        if (checksToPrint.size == 0) {
          // todo: semantic ui message, not popup alert
          alert('Please select at least one check to print');
          return;
        }
        let url = '/api/v1/checks/pdf.pdf';
        let params = api.EncodeQueryString({check_ids: [...checksToPrint]});
        window.open(`${url}${params}`, '_blank');
      }
    };

    return html.tag(Button, 'print-button', opts);
  }

  return div('component', {className: ''},
    html.h1('header', {className: 'header'}, 'Scans Count Report'),
    totalsAndPagination('top'),
    html.tag(Tables.Table, 'checks-table', {
      columns: [
        {text: '', react: printButton()},
        {
          react: filterWrapper('check_number', () => {
            return filterInput(() => checkNumber, setCheckNumber, 'number')
          }, 'Check #')
        },
        {
          react: filterWrapper('vendor_name', () => {
            return filterInput(() => vendorName, setVendorName, 'text')
          }, 'Vendor')
        },
        {
          react: filterWrapper('amount', () => {
            return filterInput(() => amount, setAmount, 'number')
          }, 'Amount')
        },
        {
          react: filterWrapper('check_date', () => {
            return filterDateRangeCheckDate(() => checkDate, setCheckDate)
          }, 'Check Date')
        },
        {
          react: filterWrapper('created_at', () => {
            return filterDateRangeScanDate(() => scanDate, setScanDate)
          }, 'Scan Date')
        }
      ],
      rows: rows()
    }),
    totalsAndPagination('bottom')
  );
}
