let truth = {};

// this is ruby style falseness
// NOTE: 0 is not falsy
truth['isFalsy'] = (value) => {
  return typeof value === 'undefined' || value === null || value === false
}

// the opposite of above
truth['isTruthy'] = (value) => {
  return !truth.isFalsy(value);
}

export default truth;