import React, {useState, useEffect} from 'react'
import html from './html'
import truth from './truth'
import {Table} from 'semantic-ui-react'

let Tables = {};

// TODO: don't use dangerouslySetInnerHTML
Tables['HeaderCell'] = (props) => {
  let cell = html.span('span', {dangerouslySetInnerHTML: {__html: props.column.text}});
  let options = {className: `${props.column.className}`};

  if(truth.isTruthy(props.column.react))
    cell = html.span('span', options, props.column.react);

  return html.tag(Table.HeaderCell, 'cell', options, cell);
}

Tables['HeaderRow'] = (props) => {
  let children = html.tag(Tables.HeaderCell, 'empty', {column: {text: 'No data is available.'}});
  if(truth.isTruthy(props.columns) && props.columns.length > 0)
    children = props.columns.map((column,index) => html.tag(Tables.HeaderCell, index, {column: column}))
  return html.tag(Table.Row, 'row', {}, children);
}

Tables['Header'] = (props) => {
  return html.tag(
    Table.Header, 'header', {},
      html.tag(Tables.HeaderRow, 'header-row', {columns: props.columns})
  );
}

// TODO: don't use dangerouslySetInnerHTML
Tables['Cell'] = (props) => {
  let cell = html.span('span', {dangerouslySetInnerHTML: {__html: props.column.text}});
  let options = {className: `${props.column.className}`};

  if(truth.isTruthy(props.column.react))
    cell = html.span('span', options, props.column.react);

  return html.tag(Table.Cell, 'cell', options, cell);
}

Tables['Row'] = (props) => {
  if(truth.isTruthy(props.react)) {
    return props.react;
  }
  let children = html.tag(Tables.Cell, 'empty', {column: {text: 'No data is available.'}});
  if(truth.isTruthy(props.columns) && props.columns.length > 0)
    children = props.columns.map((column, index) => html.tag(Tables.Cell, index, {column: column}))
  return html.tag(Table.Row, 'row', {}, children);
}

Tables['WaitingRow'] = (props) => {
  return html.tag(Table.Row, 'waiting-row', {},
    html.tag(Table.Cell, 'waiting-cell', {
      colSpan: (truth.isTruthy(props.columns) ? props.columns.length : 0),
      className: 'center aligned'
    },
      html.i('waiting', {className: 'spinning icon sync center aligned'})
    )
  );
}

Tables['Body'] = (props) => {
  let children = [];

  if(truth.isFalsy(props.rows))
    children = html.tag(Tables.WaitingRow, 'waiting-row', {columns: props.columns});
  else if(props.rows.length == 0)
    children = html.tag(Tables.Row, 'empty', {columns: []});
  else if(props.rows.length > 0)
    children = props.rows.map((row, index) => html.tag(Tables.Row, index, row));

  return html.tag(Table.Body, 'body', {}, children);
}

Tables['Table'] = (props) => {
  return html.tag(Table, 'table', {celled: true}, [
    html.tag(Tables.Header, 'header', {columns: props.columns}),
    html.tag(Tables.Body, 'body', {columns: props.columns, rows: props.rows})
  ]);
};

export default Tables;