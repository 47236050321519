import React, {useState, useEffect} from 'react'
import api from './api'

// takes a URL and a function to convert data from API to the array we need
// returns the state, setState, and a fetch function that takes a filter object
// state is an object, defaults to entities: null, errors: [], and waiting: true
export default function useFetchWithErrors(url, dataTransform) {
  const [state, setState] = useState({
    entities: null,
    errors: [],
    waiting: true
  });

  // if not defined, we just pass it on through
  if(typeof dataTransform == 'undefined')
    dataTransform = (data) => {
      return data;
    };

  const fetchMethod = (filters) => {
    setState({
      entities: null,
      errors: [],
      waiting: true
    });
    return api.get(url, filters, {})
      .then(response => response.json())
      .then(data => {
        setState({
          entities: dataTransform(data),
          errors: [],
          waiting: false
        });
      });
  }

  return [state, setState, fetchMethod];
}
