import html from '../utils/html'
import truth from '../utils/truth'
import api from '../utils/api'
import React, {useState, useEffect} from 'react'
import {Table, Popup, Modal, Image} from 'semantic-ui-react'
import Tables from '../utils/tables'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import UserForm from './form'

export default function UserRow(props) {
  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  // common button
  const button = (key, color, icon, text, callback) => {
    return html.tag(Popup, key, {
      trigger: html.button(key, {
        onClick: (e) => callback(e),
        className: `ui ${color} button`
      }, html.i('icon', {className: `${icon} icon`})),
    }, text);
  }

  //
  // button callbacks
  //

  const edit = (e) => {
    setEditing(true);
  }

  const save = (e) => {
    setEditing(false) ;
    api.put(`/api/v1/users/${props.user.id}`, {
      user: {

      }
    }, {}).then((response) => response.json())
      .catch((error) => console.log('error', error))
  }


  const viewUser = (e) => {
    setOpen(true)
    api.get(`/api/v1/users/${props.user.id}`, {}, {})
      .then(response => response.json())
      .then((data) => {
        setUser(data);
      })
  }

  const userView = (e) => {
    if(truth.isTruthy(user)) {
      return html.tag(UserForm, 'user-form', {
        user: props.user,
        onClose: () => setOpen(false),
        onSave: () => {
          setOpen(false);
          props.onSave();
        }
      });
    }
    return 'Loading...';
  }

  //
  // buttons
  //

  const cancelEditButton = () => {
    return button('cancel', 'red', 'close', 'Cancel Edit', (e) => {
      setEditing(false);
    });
  }

  const editButton = () => {
    return button('edit', 'secondary', 'pencil', 'Edit', edit);
  }

  const viewButton = () => {
    return html.span('button-modal', {},
      button('view', 'primary', 'search plus', 'View', (e) => viewUser(e)),
      html.tag(Modal, 'view-modal', {
        open: open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false)
      },[
        html.tag(Modal.Content, 'modal-content', {}, userView())
      ])
    );
  }

  const saveButton = () => {
    return button('save', 'green', 'save', 'Save', save);
  }

  const deleteButton = () => {
    return button('delete', 'red', 'trash', 'Delete', (e) => {
      api.delete(`/api/v1/users/${props.user.id}`)
        .then(response => {
          if(!response.ok)
            throw new Error(response);
          props.onDelete(response);
        })
        .catch(error => console.log('delete - error', error))
    });
  }

  const actionsColumn = () => {
    if(editing)
      return html.span('buttons', {className: 'ui grid'}, [
        html.span('left', {className: 'center aligned sixteen wide column'},
          saveButton(),
          cancelEditButton()
        )
      ]);
    return html.span('buttons', {className: 'ui grid'}, [
      html.span('left', {className: 'center aligned sixteen wide column'},
        // editButton(),
        viewButton(),
        deleteButton()
      )
    ]);
  }

  const columns = () => {
    return [
        {react: actionsColumn()},
        {text: props.user.name},
        {text: props.user.email},
        {text: props.user.roles}
      ];
  };

  return html.tag(Tables.Row, 'row', {columns: columns()});
}