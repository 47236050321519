import html from '../utils/html'
import truth from '../utils/truth'
import api from '../utils/api'
import React, {useState, useEffect} from 'react'
import {Checkbox, Button} from 'semantic-ui-react'

export default function UserForm(props) {
  const {div, label, input, h1, h2} = html;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email,  setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState(null);

  const fields = () => {
    return [
      {
        label: 'First Name',
        value: () => firstName,
        update: setFirstName,
        type: 'text'
      }, {
        label: 'Last Name',
        value: () => lastName,
        update: setLastName,
        type: 'text'
      }, {
        label: 'Email',
        value: () => email,
        update: setEmail,
        type: 'email'
      }, {
        label: 'Password',
        value: () => password,
        update: setPassword,
        type: 'password'
      }, {
        label: 'Password (again)',
        value: () => passwordConfirmation,
        update: setPasswordConfirmation,
        type: 'password'
      }
    ];
  }

  const reload = (e) => {
    if(truth.isTruthy(props.user) && truth.isTruthy(props.user.id))
      api.get(`/api/v1/users/${props.user.id}`, {}, {})
        .then(response => response.json())
        .then((data) => {
          setUser(data);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setEmail(data.email);
          setRoles(data.roles);
        });
  }

  const save = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: passwordConfirmation
    };
    if(truth.isTruthy(props.user))
      updateUser(data).then(() => props.onSave());
    else
      addUser(data).then(() => props.onSave());
  }

  const updateUser = (options) => {
    return api.put(`/api/v1/users/${props.user.id}`, {
      user: options
    });
  }

  const addUser = (options) => {
    return api.post(`/api/v1/users`, {
      user: options
    });
  }

  const reloadRoles = (e) => {
    api.get(`/api/v1/users/${props.user.id}/roles`, {}, {})
      .then(response => response.json())
      .then((data) => {
        setRoles(data.roles);
      });
  }

  if(user == null)
    reload();

  const header = () => {
    let text = 'Add User';
    if(truth.isTruthy(props.user) && truth.isTruthy(props.user.id))
      text = 'Edit User';
    return h1('header', {className: 'ui header'}, text);
  }

  const formField = (field, index) => {
    return div(field.index, {className: 'field'}, [
      label('label', {}, field.label),
      input('field', {
        type: field.type,
        value: field.value(),
        onChange: (e) => {
          field.update(e.target.value);
        }
      })
    ]);
  }

  const roleSelection = () => {
    if(!truth.isTruthy(roles))
      return null;
    return div('roles', {className: 'ui segment'}, [
      h2('header', {className: 'ui header'}, 'Role Assignments'),
      roles.map((role, index) => {
        return div(index, {className: 'field'},
          html.tag(Checkbox, 'check', {
            label: role.role.name,
            checked: role.has,
            onChange: (e) => {
              console.log(!role.has);
              updateUser({role_ids: (!role.has ? [role.role.id] : [])})
                .then(() => {
                  reloadRoles();
                });
            }
          })
        )
      })
    ]);
  }

  const buttons = () => {
    return div('buttons', {}, [
      html.tag(Button, 'save', {color: 'primary', onClick: (e) => save()}, 'Save'),
      html.tag(Button, 'close', {onClick: (e) => props.onClose()}, 'Close')
    ]);
  }

  return div('form', {className: 'ui form'},
    header(),
    fields().map((field, index) => {
      return formField(field, index);
    }),
    roleSelection(),
    buttons()
  );
}