import React, { useEffect, useState } from 'react';
import Tables from 'components/utils/tables'
import useFetchWithErrors from 'components/utils/use_fetch_with_errors';
import truth from 'components/utils/truth';
import { Icon, Popup, Button, Message } from 'semantic-ui-react';
import api from 'components/utils/api';

const ErrorSuccess = ({error, afterAction}) => {
  const act = () => {
    api.put(`/api/v1/scans/${error.filename}/archive`, {}, {})
      .then((data) => {
        afterAction();
      });
  };
  return (
    <div>
      <div>The file was successfully loaded and processed.</div>
      <Button
        size='small'
        onClick={() => act()}
        content='Archive'
        icon='archive'
        labelPosition='left'
      />
    </div>
  
  );
};

const ErrorResetAttempts = ({error, afterAction}) => {
  const act = () => {
    api.put(`/api/v1/scans/${error.filename}/reset_attempts`, {}, {})
      .then((data) => {
        afterAction();
      });
  };
  return (
    <div>
      <div>This file has been attempted to be processed too many times. This is usually a sign of a problem with the file itself. You may reset to try again or contact support.</div>
      <Button
        size='small'
        onClick={() => act()}
        content='Reset'
        icon='repeat'
        labelPosition='left'
      />
    </div>
  
  );
};

const ErrorRow = ({error, afterAction}) => {
  const [actionOpen, setActionOpen] = useState(false);

  const actionIcon = (error) => {
    let icon = 'question';
    let color = 'grey';
    let message = 'Unknown: contact support';

    switch(error.type) {
      case 'error':
        icon = 'exclamation';
        color = 'red';
        message = 'Error: contact support';
        break;
      case 'warning':
        icon = 'warning';
        color = 'yellow';
        message = 'Warning: not a problem right now, but may be in the future';
        break;
      case 'success':
        icon = 'check';
        color = 'green';
        message = 'Success: no action required';
        break;
    }

    return (
      <Popup trigger={<Icon name={icon} color={color} />} content={message} />
    );
  };

  const actionText = (error) => {
    let text = 'Unknown: contact support';
    let extra = '';
    switch(error.action) {
      case 'empty_file':
      case 'empty_file_unloaded':
        text = 'Empty file';
        extra = 'The file was empty which usually means there was an issue with the upload from the scanner to the application server. Typically this is a misconfigured FTP server, especially if in active or passive mode.';
        break;
      case 'archive':
        text = 'Sucess';
        extra = (<ErrorSuccess error={error} afterAction={afterAction} />);
        break;
      case 'queued':
        text = 'Queued';
        extra = 'An attempt to process this file has been made but it is still in the queue. This is normal and should resolve itself in a few minutes.';
        break;
      case 'not_loaded':
        text = 'Not Loaded';
        extra = 'This file has not been processed yet and will be in the next few minutes.';
        break;
      case 'too_many_attempts':
        text = 'Too Many Attempts';
        extra = (<ErrorResetAttempts error={error} afterAction={afterAction} />);
        break;
    }
    return (
      <span className="ui clearing">
        <span>{text}</span>
        &nbsp;
        <Popup
          trigger={<span className="ui right floated header mini">?</span>}
          content={extra}
          on='click'
          open={actionOpen}
          onClose={() => setActionOpen(false)}
          onOpen={() => setActionOpen(true)}
          
        />
      </span>
    );
  };

  const actionColumn = (error) => {
    return (
      <div>{actionIcon(error)}{actionText(error)}</div>
    );
  };

  return (
    <tr>
      <td>{error.filename}</td>
      <td>{error.attempts} attempts</td>
      <td>{actionColumn(error)}</td>
      <td>{error.support_contact}</td>
    </tr>    
  );
};

const CheckErrors = ({running, support_email}) => {
  const [errors, setErrors, fetch] = useFetchWithErrors('/api/v1/checks/errors', (data) => {
    console.log('checks', data);
    return data;
  });

  const reload = () => {
    fetch({});
  };

  useEffect(() => {
    reload();
  }, []);

  const tableRow = (error) => {
    return {
      react: (
        <ErrorRow error={error} afterAction={reload} />
      )
    };
  };

  const columns = () => {
    return [
      {text: 'File'},
      {text: 'Processed'},
      {text: 'Errors'},
      {text: 'Support Contact'}
    ];
  };

  const rows = () => {
    if(truth.isTruthy(errors) && truth.isTruthy(errors.entities)) {
      return errors.entities.map((error) => tableRow(error));

    }

    return null;
  };
  
  return (
    <div>
      <h1>Check Errors</h1>
      {running && (
        <Message warning>
          <Message.Header>Scan is running</Message.Header>
          <Message.Content>
            <p>The scan is currently running. If this message does not disappear after a few minutes, reload the page. If it still remains, contact support: {support_email}</p>
          </Message.Content>
        </Message>
      )}
      <Tables.Table columns={columns()} rows={rows()}/>
    </div>
  );
};

export default CheckErrors;
