let api = {};

// returns the response plus json
function formatError(response) {
  return response.json().then((data) => {
    return {
      response: response,
      json: data
    };
  })
}

function encodeQueryString(params) {
    const keys = Object.keys(params)
    return keys.length
        ? "?" + keys
            .map(key => encodeURIComponent(key)
                + "=" + encodeURIComponent(params[key]))
            .join("&")
        : ""
}

// This was copied from the new repo that contains our standard JS libs, as it's different to PMSC's current
// code and I didn't want to risk breaking other functionality
function ScanCountEncodeQueryString(params) {
  const keys = Object.keys(params);

  if (!keys.length) return "";

  const urlEq = (key, value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

  return "?" + keys.map(key => {
    const val = params[key];

    if (Array.isArray(val))
      return val.map(v => urlEq(`${key}[]`, v)).join("&");
    else
      return urlEq(key, val);
  }).join("&");
}
api['EncodeQueryString'] = (params) => ScanCountEncodeQueryString(params);

api['post'] = (url, params, options) => {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
    },
    method: 'POST',
    body: JSON.stringify(params)
  }).then((response) => {
    if(response.ok) {
      return response;
    }

    throw formatError(response);
  });
};

api['put'] = (url, params, options) => {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
    },
    method: 'PUT',
    body: JSON.stringify(params)
  }).then((response) => {
    if(response.ok) {
      return response;
    }

    throw formatError(response);
  });
};

api['delete'] = (url, params, options) => {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
    },
    method: 'DELETE',
    body: JSON.stringify(params)
  }).then((response) => {
    if(response.ok) {
      return response;
    }

    throw formatError(response);
  });
};

api['get'] = (url, params, options) => {
  return fetch(`${url}${encodeQueryString(params)}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
    }
  }).then((response) => {
    if(response.ok) {
      return response;
    }

    throw formatError(response);
  });
};

export default api;