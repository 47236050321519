import html from '../utils/html'
import truth from '../utils/truth'
import React, {useState} from 'react'

export default function MenuItem(props) {
  const {div, span, a, i} = html;
  const [collapsed, setCollasped] = useState(true);

  const menuIcon = (menu) => {
    if(truth.isFalsy(menu.icon))
      return null;
    return i('icon', {className: `icon ${menu.icon}`});
  }

  const isCollapsible = () => {
    return props.collapsible == true || props.collapsible == 'true';
  }

  const menuChildren = (menu) => {
    if(!(truth.isTruthy(menu.children) && Array.isArray(menu.children)))
      return null;

    if(collapsed !== false)
      return null;

    return [
      div('children', {className: 'menu item'},
        menu.children.map((child, index) => {
          child['index'] = index
          return html.tag(MenuItem, `menu-wrapper-${index}`, child);
        })
      )
    ];
  };

  const dropDown = () => {
    if(!isCollapsible())
      return null;
    return i('icon', {
      className: 'icon down angle'
    });
  }

  const menuText = () => {
    let options = {
      className: `link ${props.link_class}`
    };
    if(truth.isTruthy(props.method))
      options['data-method'] = props.method;

    if(!isCollapsible())
      options['href'] = props.href;

    return a('link', options, [
      menuIcon(props),
      props.text
    ]);
  }

  return div(`menu-wrapper-${props.index}`, {
      className: 'ui item',
      onClick: (e) => {
        if(isCollapsible())
          setCollasped(!collapsed)
      }
    },
      span('text', {className: props.text_class}, menuText()),
      dropDown(),
      menuChildren(props)
    );
}