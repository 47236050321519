import React, {useState} from 'react'

export default function usePagination() {
  const [state, setState] = useState({
    current_page: 1,
    pages: 1,
    per_page: 50
  });

  const didChange = (object, field) => {
    return object[field] != state[field];
  };

  const updatePaging = (props) => {
    if(
      didChange(props, 'current_page') ||
      didChange(props, 'pages') ||
      didChange(props, 'per_page')
    ) {
      setState({
        current_page: props.current_page,
        pages: props.pages,
        per_page: props.per_page
      });
    }
  };

  return [state, updatePaging];
}
